<template>
  <div class="church-list template-1">
    <page-header class="mx-lg">
      <h1>{{ translations.tcWebsiteSecurityManager }}</h1>
    </page-header>
    <div class="container">
      <data-table :title="state_title" :fields="dataTableFields" :items="webSecurityList" :addItems="addItems"
        :i18n="translations.components" @addNewMemberToSecurity="addNewMemberToSecurity"
        @activeToggleEvent="activeToggleEvent($event)">
      </data-table>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import DataTable from '@/components/DataTableWebSecurity.vue'
import { Loader } from '@/mixins/Loader'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { stateBreadcrumbMixin } from '@/mixins/breadcrumbMixinFactory'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'officers-security-manager',
  mixins: [Loader, stateBreadcrumbMixin, translationMixin],
  data() {
    return {
      translations: {},
      defaultValue: null,
      dialog: false,
      isLoading: true,
      member_List: [],
      orgKey: null,
      search: '',
      searchMember: '',
      selectedItem: [],
      selectedMember: [],
      selectedRole: [],
      showAddButton: false,
      showAddSection: false,
      showCancelButton: false,
      state_title: '',
      dataTableFields: [
        {
          label: 'Name',
          key: 'name',
        },
        {
          label: 'Site Administrator',
          key: 'site_administrator_flag',
        },
        {
          label: 'Content Contributor',
          key: 'content_contributor_flag',
        },
      ],
      addItems: {
        display: true,
        text: 'Add Member',
        action: 'addNewMemberToSecurity',
      },
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      this.orgKey = !!this.otbSnapshot.key ? this.otbSnapshot.key : this.officerToolbarSelected.country_state
      this.state_title = !!this.otbSnapshot.name ? this.otbSnapshot.name : this.userStateName

      await Promise.all([
        this.getWebSecurityManagersState(this.orgKey),
        this.getViewTranslations(),
        this.getComponentTranslations('data-table-conventions')
      ]).then(results => {
        const componentTranslations = results[2]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
      })
      this.defaultValue = constantData.web_security_manager
    } catch (e) {
      console.error('Error in OfficerSecurityManager.vue, created(), ', e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      getMemberList: 'officersModule/getMemberList',
      getWebSecurityManagersState: 'officersModule/getWebSecurityManagersState',
      saveOrUpdateSecurityManagers: 'officersModule/saveOrUpdateSecurityManagers',
      setLoadingStatus: 'menu/setLoadingStatus',
      setOTBSnapshot: 'user/setOTBSnapshot',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      updateSecurityManagers: 'officersModule/updateStateSecurityManagers',
    }),
    async individualStoreKeyAndRedirect(ind_key) {
      await this.setSelectedIndividualKey(ind_key)
      this.$router.push({
        path: '/programs/mb/member-profile/',
      })
    },
    getSelected(item) {
      if (this.selectedItem.length === 0) {
        this.selectedItem.push(item)
      } else {
        let index = this.selectedItem.findIndex((x) => x.ind_key === item.ind_key)
        if (index > -1) {
          this.selectedItem[index] = item
        } else {
          this.selectedItem.push(item)
        }
      }
    },
    back() {
      this.showAddSection = false
    },
    async activeToggleEvent(data) {
      this.setLoadingStatus(true)
      let securityItems = []
      if (data.field.key === 'site_administrator_flag') {
        data.item.site_administrator_flag = data.value
      }
      if (data.field.key === 'content_contributor_flag') {
        data.item.content_contributor_flag = data.value
      }
      securityItems.push(data.item)
      let update = securityItems.map((item) => {
        return {
          content_contributor_flag: item.content_contributor_flag,
          ind_key: item.ind_key,
          site_administrator_flag: item.site_administrator_flag,
          state: true
        }
      })
      let response = await this.updateSecurityManagers(update)
      if (response === true) {
        this.$swal({
          text: this.translations.tcSuccessful,
          icon: 'success',
          confirmButtonText: this.translations.tcOk,
        }).then((value) => {
          this.getWebSecurityManagersState(this.orgKey)
        })
      } else {
        this.$swal({
          text: this.translations.tcError,
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
        })
      }
      this.setLoadingStatus(false)
    },
    addNewMemberToSecurity() {
      this.$router.push({ name: 'add-member-to-security' })
    },
    loadTranslations() {
      this.dataTableFields[0].label = this.translations.tcName
      this.dataTableFields[1].label = this.translations.tcSiteAdministrator
      this.dataTableFields[2].label = this.translations.tcContentContributor
      this.addItems.text = this.translations.tcAddMember
    }
  },
  computed: {
    ...mapGetters({
      memberList: 'officersModule/memberList',
      otbSnapshot: 'user/otbSnapshot',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userStateName: 'user/userStateName',
      webSecurityList: 'officersModule/webStateSecurityList'
    }),
  },
  watch: {
    selectedRole: function (newval, oldval) {
      if (newval.length > 0 && this.selectedMember.length > 0) {
        this.showAddButton = true
        this.showCancelButton = true
      } else {
        this.showAddButton = false
      }
    },
    selectedMember: function (newval, oldval) {
      if (newval.length > 0 && this.selectedRole.length > 0) {
        this.showAddButton = true
        this.showCancelButton = true
      } else {
        this.showAddButton = false
      }
    },
  },
  components: {
    dataTable: DataTable,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
  async beforeRouteLeave(to, from, next) {
    await this.setOTBSnapshot({})
    next()
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
